var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"title"},[_c('h1',{style:({
      fontFamily: _vm.fonts[_vm.currentFontIndex].fontFamily,
      fontSize: `${_vm.fonts[_vm.currentFontIndex].fontSize}px`
    })},[_vm._v(" Fonts ")])]),_c('div',{staticClass:"category-filters"},_vm._l((_vm.getFontCategories),function(category,index){return _c('span',{key:index,class:[
      'category-filters__filter-button',
      { 'category-filters__filter-button--active': _vm.getCategoryFilter == category }
    ],on:{"click":function($event){return _vm.$store.dispatch('updateCategoryFilter', category)}}},[_vm._v(" "+_vm._s(category)+" ")])}),0),_c('div',{staticClass:"options"},[_c('input',{attrs:{"type":"checkbox","name":"recommended","id":"recommended"},domProps:{"checked":_vm.getRecommendedOnly},on:{"change":function($event){return _vm.$store.dispatch('toggleRecommendedOnly')}}}),_c('label',{attrs:{"for":"recommended"}},[_vm._v(" Recommended ★")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }